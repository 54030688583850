/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./PageHeader.css";
import { useNavigate, Link } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import ProfileTop from "../../img/ProfileTop.png";
import LGTop from "../../img/LGTop.png";
import useGetWalletBalance from "../hooks/useGetWalletBalance";
import ACElogo from "../../img/ACE Black.png";
import Sidebar from "./SideBar";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function PageHeader(props) {
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let userId = sessionStorage.getItem("UUID");
  let userData = JSON.parse(sessionStorage.getItem("DATA"));

  const { mutate: getWalletBalance } = useGetWalletBalance();

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    toast.success("Logout Successfully");
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const [limit, setlimit] = useState(0);
  const [data, setData] = useState([]);
  const [uid, setuid] = useState([]);
  const [username, setFname] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => {
      const uid = localStorage.getItem("uid");
      const first_name = localStorage.getItem("first_name");
      setFname(first_name);
      setuid(uid);
      // userProfile();

      const payload = {
        acc_type: "user",
        user_id: userId,
      };

      getWalletBalance(payload, {
        onSuccess: (response) => {
          setlimit(Math.round(response.data.data.limit));
        },
        onError: (error) => {
          console.log(error);
        },
      });
      if (userData) {
        setData(userData.data);
      }
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <table
        width="100%"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        style={{
          backgroundColor: "rgb(29, 29, 29)",
          height: "65px",
        }}
      >
        <tbody>
          <tr>
            <td width="100" className="pl-4" align="center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faBars}
                  size="2xl"
                  style={{ color: "#ffffff", marginRight: "20px" }}
                  onClick={toggleSidebar}
                />
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <Link to="/MainMenu">
                  <h1 id="acelogosidebar1">ACE</h1>{" "}
                </Link>
              </div>
            </td>
            <td
              style={{ verticalAlign: "end" }}
              className="FontTextWhite"
              align="end"
            >
              <div className="profile_picture d-flex flex-column align-items-end">
                <p
                  align="end"
                  className="mb-1 pr-4 pt-2"
                  style={{ textTransform: "uppercase", fontSize: "12px" }}
                >
                  {data ? data.name : ""} : {data ? data.code : ""}
                </p>
                <p
                  align="end"
                  className="mb-1 pr-4"
                  style={{ fontSize: "12px" }}
                >
                  Coins : {limit ? limit : 0}
                </p>
              </div>
            </td>
            {/* <td
              width="55"
              align="center"
              className="FontTextWhite"
              style={{ padding: "8px" }}
            >
             
              <span style={{ cursor: "pointer" }} onClick={logout}>
                <img src={LGTop} alt="" width="40" height="40" />

                <span>Logout</span>
              </span>
        
            </td> */}
          </tr>
        </tbody>
      </table>

      {/* <div
        className="welcomeMarquee"
        style={{
          backgroundColor: "rgb(29, 29, 29)",
        }}
      >
        <marquee>
          प्रिय ग्राहक एडवांस सेशन और टॉस का रेट चालू है धन्यवाद
        </marquee>
      </div> */}
    </>
  );
}
